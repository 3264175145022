import React, { Component } from 'react';
import _ from 'lodash';
import * as styles from './helpArticles.module.less';
import bulkEditorHelpArticles from './bulkEditorHelpArticles.json';
import analyticsHelpArticles from './analyticsHelpArticles.json';
import exporterHelpArticles from './exporterHelpArticles.json';

/**
 * The HelpArticles Component within the Home Page
 */
class HelpArticles extends Component {
  constructor(props) {
    super(props);

    this.workspaceShopifyStores = props.workspaceShopifyStores || [];

    // Figure out which help articles to show
    let helpArticles = [];

    let activeAppsForAllStores = new Set();

    if (this.workspaceShopifyStores) {
      // Iterate through stores and extract apps with value `true`
      _.forEach(this.workspaceShopifyStores, (storeData) => {
        const activeAppsForStore = _.keys(_.pickBy(storeData.apps, value => value === true));
        activeAppsForStore.forEach(app => activeAppsForAllStores.add(app));
      });

      // Convert the Set to an Array if needed
      activeAppsForAllStores = Array.from(activeAppsForAllStores);

      // Add bulk editor help articles
      if (activeAppsForAllStores.indexOf('bulk_editor') > -1) {
        helpArticles = helpArticles.concat(bulkEditorHelpArticles);
      }

      // Add analytics help articles
      if (activeAppsForAllStores.indexOf('analytics') > -1) {
        helpArticles = helpArticles.concat(analyticsHelpArticles);
      }

      // Add the Exporter help articles ONLY IF bulk editor not installed
      if (activeAppsForAllStores.indexOf('exporter') > -1 && activeAppsForAllStores.indexOf('bulk_editor') < 0) {
        helpArticles = helpArticles.concat(exporterHelpArticles);
      }
    }


    this.state = {
      helpArticles,
      activeAppsForAllStores,
    };
  }

  render() {
    return (
      <div className={styles.helpArticlesComponent}>
        <div>
          <h2>Getting started with Mixtable</h2>
        </div>

        <div className={styles.helpArticlesCatalog}>
          {this.state.helpArticles.map(helpArticle => (
            <a key={helpArticle.url}
              href={`${helpArticle.url}?utm_source=app&utm_medium=referral&utm_campaign=dashboard`} target={'_blank'}
              rel="noreferrer">
              <div className={styles.helpArticlesCard}>
                <div className={styles.helpArticlesCardHeader}>
                  <span className={'icon-help-thin'}/>
                  <h3>{helpArticle.title}</h3>
                </div>
                <div className={styles.helpArticlesCardBody}>
                  {helpArticle.description}
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  }
}

export default HelpArticles;
