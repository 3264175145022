@import "../styles/variables";

.homeComponent {
  padding: 20px;

  h2 {
    font-size: 21px;
    line-height: 25px;
    margin-top: 0;
  }

  .loadingAnimationContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -100px;
    margin-left: -100px;
    width: 200px;
    height: 200px;
    text-align: center;
  }

  .contentContainer {
    display: flex;
    width: 100%;
    gap: 20px;

    .leftColumn {
      flex: 0 0 75%;
      padding-right: 20px;
    }

    .rightColumn {
      flex: 0 0 25%;
    }


    .sectionHeading {
      margin: 0;
      font-weight: 600;

      font-size: 16px;
      line-height: 32px;

      padding-bottom: 10px;
    }
  }
}
