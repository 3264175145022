[
  {
    "title": "Net Sales analysis",
    "description": "Analyze the product Net Sales for your Shopify store",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/net-sales-analytics/"
  },
  {
    "title": "Sales analytics - last 30 days",
    "description": "Monitor the sales for your store using a dynamic last 30 days period",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/shopify-analytics-last-30-days/"
  },
  {
    "title": "Customer country analytics",
    "description": "Analyze your store sales by customer country",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/customer-country-analytics/"
  },
  {
    "title": "Customer sales analytics",
    "description": "Analyze your store sales by individual customer",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/shopify-customer-analytics/"
  }
]
