import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as styles from './workbooksCatalog.module.less';

/**
 * The Workbooks Catalog Component within the Home Page
 */
class WorkbooksCatalog extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openWorkbooksScreen: props.openWorkbooksScreen,
      openIntegrationsCatalogScreen: props.openIntegrationsCatalogScreen,
    };

    this.state = {
      workbooks: props.workbooks,
      masterEditions: props.masterEditions,
    };
  }

  componentDidMount() {}

  /**
   * Helper method to get the appropriate url for the master edition
   * @param workbookId
   * @returns {string}
   */
  getMasterEditionUrl = (workbookId) => {
    if (!this.state.masterEditions[workbookId] || !this.state.masterEditions[workbookId].worksheets) {
      return '/home';
    }
    return `/workbook/${this.state.masterEditions[workbookId].id}/${this.state.masterEditions[workbookId].worksheets[0].id}`;
  }

  render() {
    return (
      <div className={styles.workbooksCatalogComponent}>
        {this.state.workbooks && this.state.workbooks.length > 0 && <div className={styles.workbooksCatalog}>
          {this.state.workbooks.map(workbook => (
            <div key={workbook.id} className={styles.workbookCard}>
              <Link key={workbook.id} to={(this.getMasterEditionUrl(workbook.id))}>
                <div className={styles.workbookCardHeader}>
                  <div className={styles.icon}>
                    <span className={'icon-workbook'}/>
                  </div>
                  <div className={styles.workbookName}>{workbook.name}</div>
                  <div className={styles.workbookStores}>
                    {workbook.stores && workbook.stores.length > 0 && workbook.stores.map(store => (
                      <div key={store}>
                        {store}
                      </div>
                    ))}
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>}
      </div>
    );
  }
}

export default WorkbooksCatalog;
