@import "../../../styles/variables";

.templateCatalogComponent {
  .templateIntro {
    padding-bottom: 20px;
  }

  .templatesList {
    display: flex;
    flex-wrap: wrap; /* Allows elements to wrap to the next row */
    align-items: stretch; /* make all heights the same */
    gap: 10px; /* Optional: Adds space between squares */
  }

  .template {
    border-radius: @border-radius;
    border: 1px solid @border-color-1;
    cursor: pointer;
    background: @white;

    min-height: 80px;
    width: calc(25% - 10px); /* Each square takes up 25% minus gap adjustment */
    position: relative;

    flex: 0 0 calc(25% - 10px); /* Four items per row with gap */

    .sectionIcon {
      position: absolute;
      top: 10px;
      left: 7px;

      vertical-align: middle;
      margin-right: 8px;
      opacity: 0.8;
      height: 15px;
      width: 15px;
    }

    &:hover {
      box-shadow: 0 4px 30px rgba(48,48,54,.04);
      span {
        opacity: 1.0;
      }
    }

    .content {
      padding: 7px 7px 7px 30px;

      .title {
        font-weight: 600;
        padding-bottom: 2px;
        padding-right: 16px;
      }

      .description {
        font-size: 0.8em;
        line-height: 1.5em;
        padding: 3px 0 0 0px;
      }
    }
  }



  .checkboxContainer {
    position: absolute;
    top: 10px;
    right: 0;
  }

  .callout {
    margin-bottom: 30px;
  }

  .emptyPlaceholder {
    height: 60px;
  }

  .createWorkbookButton {
    padding-top: 10px;
    margin: auto;
  }
}
