import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import * as styles from './workbooksPage.module.less';
import LoadingAnimation from '../../loadingAnimation';
import WorkbooksCatalog from '../workbooksCatalog';
import AuthService from '../../../lib/AuthService';

/**
 * The Workbooks Catalog Component within the Home Page
 */
class WorkbooksPage extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openIntegrationsCatalogScreen: props.openIntegrationsCatalogScreen,
      showError: props.showError,
    };

    this.state = {
      workspaceId: props.workspaceId,
      workbooks: props.workbooks,
      masterEditions: props.masterEditions,
      userWorkbooks: [],
      sharedWorkbooks: [],
      showBlockingOverlay: false,
      storesAppsInstalled: props.storesAppsInstalled,
      isAddShopifyLinkedWorkbookDialogOpened: false,
    };

    // Store the Auth provider
    this.authProvider = AuthService.getInstance().provider;
  }

  async componentDidMount() {
    /* Check if we have workbooks */
    if (!this.state.workbooks) {
      return;
    }

    const workbooks = this.state.workbooks;

    /* Store the workbooks where user is the owner and sort it */
    const userWorkbooks = workbooks
      .filter((workbook) => {
        if (!workbook.relationship || workbook.relationship !== 'owner') {
          return false;
        }
        return workbook;
      }).sort((a, b) => a.name.localeCompare(b.name));

    /* Store the workbooks where user is collaborator and sort it */
    const sharedWorkbooks = workbooks
      .filter((workbook) => {
        if (!workbook.relationship || workbook.relationship !== 'collaborator') {
          return false;
        }

        return workbook;
      })
      .sort((a, b) => a.name.localeCompare(b.name));

    this.setState({
      workbooks,
      userWorkbooks,
      sharedWorkbooks,
    });
  }

  openAddShopifyWorkbookDialog = () => {
    this.setState({ isAddShopifyLinkedWorkbookDialogOpened: true });
  }

  closeAddShopifyWorkbookDialog = () => {
    this.setState({ isAddShopifyLinkedWorkbookDialogOpened: false });
  }


  render() {
    return (
      <div className={styles.workbooksComponent}>

        {/* Pasting data overlay & spinner*/}
        {this.state.showBlockingOverlay && <div className={styles.blockingOverlayContainer}>
          <div className={styles.blockingOverlay}>
            <div>
              <LoadingAnimation/>
            </div>
            <div className={styles.description}>
              Please wait, we will redirect you to the new workbook
            </div>
          </div>
        </div>}

        {/* Shows workbooks where user is the owner */}
        {this.state.userWorkbooks && this.state.userWorkbooks.length > 0 && (
          <div className={styles.workbookCatalogContainer}>
            <div>
              <h2>Your workbooks</h2>
            </div>
            <WorkbooksCatalog
              workbooks={this.state.userWorkbooks}
              masterEditions={this.state.masterEditions}
            />
          </div>
        )}

        {/* Shows workbooks where user is collaborator */}
        {this.state.sharedWorkbooks && this.state.sharedWorkbooks.length > 0 && <div className={styles.workbookCatalogContainer}>
          <div>
            <h2>Workbooks shared with you</h2>
          </div>
          <WorkbooksCatalog
            workbooks={this.state.sharedWorkbooks}
            masterEditions={this.state.masterEditions}
          />
        </div>}
      </div>
    );
  }
}

export default withRouter(WorkbooksPage);
