import React from 'react';
import axios from 'axios';
// import queryString from 'query-string';
import { useAppBridge } from '@shopify/app-bridge-react';
import API from '../../config';


/**
 * Start page component - either sends the user to the /home page, or gets her to login
 */
class ShopifyLogin extends React.Component {
  constructor(props) {
    super(props);

    // const queryParams = queryString.parse(location.search);
    // console.log(`ShopifyLogin queryParams: ${JSON.stringify(queryParams)}`);

    this.state = {};

    /* Determine which app we are using */
    this.APPID = 'BULK_EDITOR'; // default app id is bulk editor
    if (window.location.pathname.indexOf('/shopifyLoginExporter') > -1) {
      // We are in Exporter
      this.APPID = 'EXPORTER';
    } else if (window.location.pathname.indexOf('/shopifyLoginAnalytics') > -1) {
      // We are in Analytics
      this.APPID = 'ANALYTICS';
    } else if (window.location.pathname.indexOf('/shopifyLoginPoligon') > -1) {
      // We are in Analytics
      this.APPID = 'POLIGON';
    }
    // console.log(`ShopifyLogin  ==>>  APPID = ${this.APPID}`);
  }

  async componentDidMount() {
    // console.log('ShopifyLogin componentDidMount - START');
    const shopify = useAppBridge(); // in embedded below

    const sessionToken = await shopify.idToken();
    // console.log(`Embedded - session token: ${sessionToken}`);

    try {
      // Get the installed status for the store
      await axios.get(`${API.API_URL}/1.0/integrations/shopify/connector/${this.APPID.toLowerCase()}/token-exchange`, {
        headers: {
          Token: `shopify ${sessionToken}`,
          appid: this.APPID.toLowerCase(),
        },
      });
      // console.log(`Token exchange done for  ==>>  ${shopify.config.shop}  ==>>  ${this.APPID.toLowerCase()}`);
    } catch (error) {
      console.log(`Error starting token exchange: ${error}`);
    }

    // Go to Entry point, now that we are logged in and everything
    // window.location = `/shopifyEntry?store=${shopify.config.shop}&embedded=true&appId=analytics`;
    this.props.history.push(`/shopifyEntry?store=${shopify.config.shop}&embedded=true&appId=${this.APPID.toLowerCase()}`);
  }


  render() {
    return (<div style={{ padding: '20px' }}>
      Mixtable secure login ...
    </div>);
  }
}

export default ShopifyLogin;
