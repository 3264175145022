import React from 'react';
import queryString from 'query-string';
import AuthService from '../../lib/AuthService';
import LoadingAnimation from '../../components/loadingAnimation';
import AxiosClient from '../../lib/AxiosClient';
import Config from '../../config';

/**
 * Start page component - either sends the user to the /home page, or gets her to login
 */
class ShopifyEntry extends React.Component {
  constructor(props) {
    super(props);
    // console.log('ShopifyEntry');

    const parsedQueryParams = queryString.parse(location.search);

    this.store = parsedQueryParams.store;
    this.appId = parsedQueryParams.appId;

    this.state = {};
  }

  async componentDidMount() {
    // console.log(`ShopifyEntry - about to work - ${this.store} - ${this.appId}`);

    // First, run the Shopify Admin authentication code. Does initial set up if first time app open in admin
    try {
      await AuthService.getInstance().handleShopifyAuthentication(this.appId);
    } catch (err) {
      console.log(`Shopify admin login error: ${err}`);
    }

    const response = await AxiosClient.getInstance().get(`${Config.API_URL}/1.0/integrations/shopify/${this.store}/hasShopifyEmbeddedIntegration`);

    // Set the state with the variable, no matter what
    this.setState({
      hasIntegrationConfig: response.data.hasIntegrationConfig || false,
      loaded: true,
    }, () => {
      // If user has integration config, then send them home
      if (response.data.hasIntegrationConfig) {
        this.props.history.push('/home');
      }
    });
  }


  render() {
    return (<div style={{ padding: '20px' }}>
      {/* Shopify login ...*/}
      {/* {!this.state.loaded && <LoadingAnimation/>}*/}

      {this.state.loaded && !this.state.hasIntegrationConfig && <div>
        This Shopify store is set up to be used with the Mixtable Web App, and not from within the Shopify admin.
        <br/>
        <br/>
        Please log into the Mixtable Web App at <strong><a target={'_blank'} href={'https://www.mixtable.com/login/'} rel="noreferrer">https://www.mixtable.com/login/</a></strong> to access your workbooks.
        <br/>
        <br/>
        If you believe that you are seeing this message in error, please contact <a href={'mailto:team@mixtable.com'}>team@mixtable.com</a>
      </div>}
    </div>);
  }
}

export default ShopifyEntry;
