import React, { Component } from 'react';
import * as styles from './header.module.less';

/**
 * The Header Component within the Home Page
 */
class Header extends Component {
  constructor(props) {
    super(props);

    /* Parent functions we passed to the component */
    this.parentFunctions = {
      openAddShopifyWorkbookDialog: props.openAddShopifyWorkbookDialog,
      createWorkbook: props.createWorkbook,
    };

    this.state = {
      hasWorkbooks: props.hasWorkbooks,
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className={styles.workbookPageHeaderComponent}>
        {/* If user is new and do not have workbooks just show welcome back message */}
        <div className={styles.headerContainer}>
          <div className={styles.welcomeMessage}>
            <h1>Welcome to Mixtable</h1>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
