import React, { Component } from 'react';
import _ from 'lodash';
import { v1 as uuidv1 } from 'uuid';
import { Button, Icon } from '@blueprintjs/core';
import AxiosClient from '../../lib/AxiosClient';
import API from '../../config';
import * as styles from './initialDownloadWidget.module.less';
import LoadingAnimation from '../loadingAnimation';


/**
 * Initial Download Widget Component
 */
class InitialDownloadWidget extends Component {
  constructor(props) {
    super(props);

    /** NOT MANDATORY - Used only for the eventListener */
    this.virtualWorkbook = props.virtualWorkbook;

    this.parentFunctions = {
      showBillingOverlay: props.showBillingOverlay,
    };

    this.eventListenerId = uuidv1();

    this.state = {
      storeId: props.storeId,
      shopifyObjectType: props.shopifyObjectType,
      worksheetStoreCanUseTheApp: null,
      reloadingData: false,
      initialDataDownloadInProgress: false,
      initialDownloadWidgetExpanded: true,
      initialDataDownloadStoreId: null,
      initialDataDownloadObjectsInProgress: [],
      initialDataDownloadObjectsNotStarted: [],
      initialDataDownloadObjectsDone: [],
    };

    // Debounced functions
    this.loadInitialDownloadFlagData_debounced = _.debounce(this.loadInitialDownloadFlagData.bind(this), 500);
    this.showWidget_debounced = _.debounce(this.showWidget.bind(this), 500);
  }

  /**
   * Refreshes all initial download flags and widgets
   * @returns {Promise<void>}
   */
  refreshInitialDownloadStatus = async () => {
    await this.loadInitialDownloadFlagData();

    await this.showInitialDownloadWidget();

    // console.log(`refreshInitialDownloadStatus CALLED with initialDataDownloadInProgress: ${this.state.initialDataDownloadInProgress}`);

    if (this.state.initialDataDownloadInProgress) {
      setTimeout(async () => {
        await this.refreshInitialDownloadStatus();
      }, 1000 * 5);
    }
  }

  /**
   * Helper method to decide if we should show the initial download widget
   * @returns {Promise<void>}
   */
  showInitialDownloadWidget = async () => {
    // Get our store id
    const storeId = this.state.storeId;
    const worksheetShopifyObjectType = this.state.shopifyObjectType;

    /* If not store id do not show anything */
    if (!storeId) {
      return;
    }

    /* Figure out if we need to show the initial-data-download widget */
    let initialDataDownloadInProgress = false;

    /* Figure out if we should expand the initial download widget */
    let initialDownloadWidgetExpanded = this.state.initialDownloadWidgetExpanded;

    const initialDataDownloadObjectsInProgress = [];
    const initialDataDownloadObjectsNotStarted = [];
    const initialDataDownloadObjectsQueued = [];
    const initialDataDownloadObjectsDone = [];

    ['product', 'product-variant', 'inventory', 'collection', 'order', 'customer'].forEach((shopifyObjectType) => {
      /* Set core initial download values */
      let subKey = 'initial-download';

      if (['product', 'product-variant', 'inventory', 'collection', 'customer'].indexOf(shopifyObjectType) > -1) {
        subKey = 'initial-graphql-download';
      }

      let coreDataDownloadKey = `${storeId}-${subKey}-${shopifyObjectType}s`; // core data object type is plural - product => products

      if (shopifyObjectType === 'inventory') {
        coreDataDownloadKey = `${storeId}-${subKey}-inventory`;
      }

      const coreDownloadValue = this.state[coreDataDownloadKey] || 'not-started';

      // If object's core is downloading (in-progress), show widget, on any worksheet
      initialDataDownloadInProgress = initialDataDownloadInProgress || coreDownloadValue === 'in-progress';
      let coreLabel = `${shopifyObjectType.charAt(0).toUpperCase()}${shopifyObjectType.slice(1)}s`;

      // Change the “orders” label to “Orders and related data”. Because this will also get OLIs, refunds, etc.
      if (shopifyObjectType === 'order') {
        coreLabel = 'Orders and related data';
      }

      if (shopifyObjectType === 'product-variant') {
        coreLabel = 'Product Variants';
      }

      if (shopifyObjectType === 'inventory') {
        coreLabel = 'Inventory Levels';
      }

      const coreInitialDownloadObject = {
        shopifyObjectType: shopifyObjectType,
        isMetafields: false,
        label: coreLabel,
        value: coreDownloadValue,
        showDownloadButton: ['order', 'customer'].indexOf(shopifyObjectType) > -1 && coreDownloadValue === 'not-started',
      };

      if (coreDownloadValue === 'in-progress') {
        initialDataDownloadObjectsInProgress.push(coreInitialDownloadObject);
      } else if (coreDownloadValue === 'not-started') {
        initialDataDownloadObjectsNotStarted.push(coreInitialDownloadObject);
      } else if (coreDownloadValue === 'queued') {
        initialDataDownloadObjectsQueued.push(coreInitialDownloadObject);
      } else if (coreDownloadValue === 'done') {
        initialDataDownloadObjectsDone.push(coreInitialDownloadObject);
      }

      if (['inventory'].indexOf(shopifyObjectType) > -1) {
        return;
      }

      /* Set metafields initial download values */
      const metafieldDataDownloadKey = `${storeId}-${subKey}-${shopifyObjectType}-metafields`;
      const metafieldDownloadValue = this.state[metafieldDataDownloadKey] || 'not-started';

      // If object metafields are downloading (in-progress), show widget, on any worksheet
      initialDataDownloadInProgress = initialDataDownloadInProgress || metafieldDownloadValue === 'in-progress';
      let metafieldLabel = `${shopifyObjectType.charAt(0).toUpperCase()}${shopifyObjectType.slice(1)} Metafields`;

      if (shopifyObjectType === 'product-variant') {
        metafieldLabel = 'Product Variant Metafields';
      }

      const metafieldInitialDownloadObject = {
        shopifyObjectType: shopifyObjectType,
        isMetafields: true,
        label: metafieldLabel,
        value: metafieldDownloadValue,
        showDownloadButton: false,
      };

      if (metafieldDownloadValue === 'in-progress') {
        initialDataDownloadObjectsInProgress.push(metafieldInitialDownloadObject);
      } else if (metafieldDownloadValue === 'not-started') {
        initialDataDownloadObjectsNotStarted.push(metafieldInitialDownloadObject);
      } else if (metafieldDownloadValue === 'done') {
        initialDataDownloadObjectsDone.push(metafieldInitialDownloadObject);
      }

      // If on orders/customers worksheet, and no orders/customers metafields downloaded, show and expand
      if (worksheetShopifyObjectType && worksheetShopifyObjectType.toLowerCase() === shopifyObjectType && ['order', 'customer', 'Customer'].indexOf(worksheetShopifyObjectType) > -1 && metafieldDownloadValue !== 'done') {
        initialDataDownloadInProgress = initialDataDownloadInProgress || true;
        initialDownloadWidgetExpanded = initialDownloadWidgetExpanded || true;
      }
    });

    await this.setState({
      initialDataDownloadInProgress,
      initialDownloadWidgetExpanded,
      initialDataDownloadObjectsInProgress,
      initialDataDownloadObjectsNotStarted,
      initialDataDownloadObjectsQueued,
      initialDataDownloadObjectsDone,
      initialDataDownloadStoreId: storeId,
    });
  }


  /**
   * Sets the current widget to be shown
   */
  showWidget = async () => {
    await this.refreshInitialDownloadStatus();
  }

  /**
   * Loads the data for the onboarding-related flags for the stores in this workbook
   * @returns {Promise<void>}
   */
  async loadInitialDownloadFlagData() {
    const statePayload = {};

    const storeId = this.state.storeId;

    if (!storeId) return;

    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/2.0/flag/store/${storeId}/initialDownload`);

      if (response.data) {
        // console.log(`store response.data: ${JSON.stringify(response.data)}`);

        // eslint-disable-next-line no-restricted-syntax
        for (const flag of response.data) {
          statePayload[flag.key] = flag.value;
        }
      }
    } catch (err) {
      console.log(`Error getting store initial download flags ${storeId} - ${err}`);
    }

    this.setState(statePayload);

    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/2.0/flag/store/${storeId}/initialGraphQLDownload`);

      if (response.data) {
        // console.log(`store response.data: ${JSON.stringify(response.data)}`);

        // eslint-disable-next-line no-restricted-syntax
        for (const flag of response.data) {
          statePayload[flag.key] = flag.value;
        }
      }
    } catch (err) {
      console.log(`Error getting store initial download flags ${storeId} - ${err}`);
    }

    // console.log(`store statePayload: ${JSON.stringify(statePayload)}`);

    this.setState(statePayload);
  }

  /**
   * Mounting
   * @returns {Promise<void>}
   */
  async componentDidMount() {
    await this.refreshInitialDownloadStatus();

    if (this.virtualWorkbook) {
      this.virtualWorkbook.addEventListener(this.virtualWorkbook.eventsList.INITIAL_DOWNLOAD_STARTED, this.eventListenerId, async () => {
        await this.refreshInitialDownloadStatus();
      });
    }
  }

  /**
   * Listen for updates
   * @param {*} prevProps
   * @param {*} prevState
   */
  async componentDidUpdate(prevProps, prevState) {
    if (prevProps.storeId !== this.props.storeId) {
      await this.setState({
        storeId: this.props.storeId,
      });
      await this.refreshInitialDownloadStatus();
    } else if (prevProps.shopifyObjectType !== this.state.shopifyObjectType) {
      await this.setState({
        shopifyObjectType: this.props.shopifyObjectType,
      });
      await this.refreshInitialDownloadStatus();
    }
  }

  /**
   * Starts initial download of the specified object
   * @param shopifyObject
   * @returns {Promise<void>}
   */
  reloadData = async (shopifyObject) => {
    await this.setState({
      reloadingData: true,
    });

    if (['order', 'customer'].indexOf(shopifyObject) > -1) {
      try {
        await AxiosClient.getInstance().get(`${API.API_URL}/1.0/integrations/shopify/${this.state.storeId}/reloadStoreData/${shopifyObject}`);
      } catch (err) {
        this.logger.error('reloadShopifyStoreData', `Error getting data: ${err}`);
      }
    }

    await this.showWidget();

    await this.setState({
      reloadingData: false,
    });
  }

  render() {
    return (
      <div className={styles.initialDownloadWidgetComponent}>
        {/* Data loading widget*/}
        {this.state.initialDataDownloadInProgress && <div className={styles.initialDataLoading}>
          <div className={styles.headline}>
            <div className={[styles.downloadStatus, styles.inProgressInHeadline].join(' ')}>
              <Icon icon={'arrow-down'} size={9}/>
            </div>
            <strong>{this.state.initialDataDownloadStoreId}</strong> store data is downloading.
            <div>Please wait for the initial data download to complete before creating a workbook with Shopify data.</div>
          </div>

          {/* Currently downloading*/}
          {/* {this.state.initialDataDownloadObjectsInProgress.length > 0 && <div className={styles.currentlyDownloading}>*/}
          {/*  Currently downloading: <strong>{this.state.initialDataDownloadObjectsInProgress[0].label}</strong>*/}
          {/* </div>}*/}

          {/* Next up*/}
          {/* {this.state.initialDataDownloadObjectsNotStarted.length > 0 && <div className={styles.nextUpDownloading}>*/}
          {/*  Next up: <strong>{this.state.initialDataDownloadObjectsNotStarted[0].label}</strong>*/}
          {/* </div>}*/}

          {this.state.initialDownloadWidgetExpanded && <div className={styles.initialDataLoadingExpanded}>

            {this.state.initialDataDownloadObjectsDone.map((initialDownloadObject, index) => (
              <div key={index} className={styles.initialDownloadExpandedRow}>
                <div className={[styles.downloadStatus, styles.done].join(' ')}>
                  <Icon icon={'tick'} size={11}/>
                </div> {initialDownloadObject.label}
              </div>
            ))}

            {this.state.initialDataDownloadObjectsInProgress.map((initialDownloadObject, index) => (
              <div key={index} className={styles.initialDownloadExpandedRow}>
                <div className={[styles.downloadStatus, styles.inProgress].join(' ')}>
                  <Icon icon={'arrow-down'} size={9}/>
                </div>
                <strong>{initialDownloadObject.label}</strong>
              </div>
            ))}

            {this.state.initialDataDownloadObjectsQueued.map((initialDownloadObject, index) => (
              <div key={index} className={styles.initialDownloadExpandedRow}>
                <div className={[styles.downloadStatus, styles.inProgress].join(' ')}>
                  <Icon icon={'arrow-down'} size={9}/>
                </div>
                {initialDownloadObject.label} (waiting to start)
              </div>
            ))}

            {this.state.initialDataDownloadObjectsNotStarted.map((initialDownloadObject, index) => (
              <div key={index} className={styles.initialDownloadExpandedRow}>
                <div className={[styles.downloadStatus, styles.notStarted].join(' ')}></div>
                {initialDownloadObject.label}
                {initialDownloadObject.showDownloadButton && <div className={styles.initiateManualDataDownload}>
                  {initialDownloadObject.label} do not automatically download upon installing Mixtable. To load the data, please click the button below
                  <div className={styles.initialManualDataDownloadButton}>
                    <Button
                      text={'Start download'}
                      icon={'circle-arrow-down'}
                      style={{ }}
                      minimal={false}
                      onClick={async () => { await this.reloadData(initialDownloadObject.shopifyObjectType); }}
                      loading={this.state.reloadingData}
                    />
                  </div>
                </div>}
              </div>
            ))}
          </div>}

          {/* <Button*/}
          {/*  style={{ marginTop: '5px' }}*/}
          {/*  fill={true}*/}
          {/*  icon={this.state.initialDownloadWidgetExpanded ? 'chevron-up' : 'chevron-down'}*/}
          {/*  text={this.state.initialDownloadWidgetExpanded ? 'Show less' : 'Show more'}*/}
          {/*  minimal={true}*/}
          {/*  onClick={() => {*/}
          {/*    this.setState({*/}
          {/*      initialDownloadWidgetExpanded: !this.state.initialDownloadWidgetExpanded,*/}
          {/*    });*/}
          {/*  }}*/}
          {/* />*/}
        </div>}
      </div>
    );
  }
}

export default InitialDownloadWidget;
