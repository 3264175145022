.workbookPageHeaderComponent {
  //margin-bottom: 50px;

  .headerContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;


    .welcomeMessage {
      text-align: right;
      font-size: 16px;

      h1 {
        font-size: 24px;
        padding: 0;
        margin: 0;
        line-height: 1.0em;
        font-weight: 500;
      }
    }


    .left {
      .createWorkbookButtons {
        display: flex;
        gap: 10px;
      }
    }
  }
}
