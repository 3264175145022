@import "../../styles/variables";

.initialDownloadWidgetComponent {
  .initialDataLoading {
    padding-bottom: 20px;
    width: 100%;
    line-height: 1.5em;
    margin-bottom: 30px;

    //border: 1px solid @foreground-primary;
    border-radius: @border-radius;
    background: @white;

    box-shadow: 0 1px 10px rgba(66, 119, 98, 0.7);

    .headline {
      margin-bottom: 3px;
      position: relative;

      padding: 10px 10px 10px 30px;
      border-bottom: 1px solid @border-color-1;
      border-top-left-radius: @border-radius;
      border-top-right-radius: @border-radius;

      background: @background-base;
      background: @foreground-primary;

      color: white;
    }

    .downloadStatus {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      background-color: transparent; /* Optional: Set a background color */
      padding-right: 10px;
      position: absolute;
      left: 0;
      top: 3px;

      @keyframes slideInOut {
        0% {
          transform: translateY(-100%); /* Start above the div */
          opacity: 1;
        }
        50% {
          transform: translateY(0); /* Fully inside the div */
          opacity: 1;
        }
        100% {
          transform: translateY(100%); /* Slide out to the bottom */
          opacity: 1;
        }
      }


      &.done {
        color: @font-success-color;
      }

      &.inProgress {
        border: 2px solid @font-success-color;
        overflow: hidden; /* Ensures the icon is hidden when out of the div */

        svg {
          color: @font-success-color;
          animation: slideInOut 1.1s infinite linear;
          position: absolute;
          top: 0;
          left: 1px;
          transform: translateX(-50%); /* Center the icon horizontally */
        }
      }

      &.inProgressInHeadline {
        border: 2px solid @white;
        overflow: hidden; /* Ensures the icon is hidden when out of the div */
        top: 14px;
        left: 7px;

        svg {
          color: @white;
          animation: slideInOut 1.1s infinite linear;
          position: absolute;
          top: 0;
          left: 1px;
          transform: translateX(-50%); /* Center the icon horizontally */
        }
      }

      &.notStarted {
        border: 1px solid @border-color-1;
      }
    }

    .nextUpDownloading .currentlyDownloading {
      margin: 5px 0 0 0;
    }

    .initialDataLoadingExpanded {
      margin: 10px 10px 0 20px;
      //padding-left: 20px;

      .initialDownloadExpandedRow {
        position: relative;
        align-items: center;
        margin: 5px 0 0 0;
        padding-left: 25px;

        .initiateManualDataDownload {
          margin: 1px 0 15px 0;
          font-size: 12px;
          line-height: 15px;

          .initialManualDataDownloadButton {
            margin-top: 5px;
            button {
              font-size: 12px;
              min-height: 0;

            }
          }
        }
      }
    }
  }
}
