[
  {
    "title": "Export to Excel XLSX file",
    "description": "How to export data from Mixtable to an Excel XLSX file",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/export-shopify-data-excel-csv-xlsx/"
  },
  {
    "title": "Export to a CSV file",
    "description": "How to export data from Mixtable to CSV",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/export-shopify-data-csv/"
  },
  {
    "title": "Export products",
    "description": "How to export your Shopify products",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/export-shopify-products/"
  },
  {
    "title": "Export orders",
    "description": "How to export your Shopify orders",
    "url": "https://www.mixtable.com/help-center/user-guide/shopify/export-shopify-orders/"
  }
]
