@import "../../../styles/variables";
.helpArticlesComponent {
  margin-bottom: 50px;
  padding-right: 20px;

  .helpArticlesCatalog {
    display: grid;
    gap: 10px;
    cursor: pointer;
    align-items: stretch;

    .helpArticlesCard {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      border: 1px solid @white;
      padding: 0 20px 5px 0;

      .helpArticlesCardHeader {
        display: flex;
        height: fit-content;
        margin-bottom: 3px;
        padding-right: 20px;
        color: @foreground-primary;

        span {
          margin: 1px 10px 0 1px;
          font-size: 16px;
          color: @foreground-primary;
          opacity: 0.7;
        }

        h3 {
          margin: 0;
          overflow-wrap: anywhere;
          font-size: 14px;
          font-weight: 600;
        }
      }

      .helpArticlesCardBody {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin-left: 28px;
        color: @foreground-secondary;
        font-size: 12px;
      }

      //&:hover {
      //  box-shadow: 0 4px 30px rgba(48,48,54,.04);
      //  border: 1px solid #eeeef2;
      //}
    }
  }
}
