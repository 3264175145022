@import "../../../styles/variables";

.workbooksComponent {

  .workbookCatalogContainer {
    margin-bottom: 30px;
  }

  .blockingOverlayContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.6);
    z-index: 10000;

    .blockingOverlay {
      position: relative;
      top: 200px;
      left: calc(50vw - 200px);
      width: 400px;
      background: @white;
      border-radius: @border-radius;
      border: 1px solid @border-color-1;
      padding: 20px 20px 20px 20px;

      .description {
        padding: 20px 0 20px 0;
        width: 90%;
        margin: auto;
        text-align: center;
        font-weight: 500;
        line-height: 1.5em;
      }
    }
  }
}
