import React from 'react';
import asyncJS from 'async';
import _ from 'lodash';
import { Intent, OverlayToaster, Position, Toast2 } from '@blueprintjs/core';
import * as styles from './home.module.less';
import API from '../config';
// import IntegrationsCatalog from '../components/integrationsCatalog';
import Logger from '../models/logger';
import WorkbooksPage from '../components/homeComponents/workbooksPage';
// import Sidebar from '../components/homeComponents/sidebar';
import LoadingAnimation from '../components/loadingAnimation';
import AxiosClient from '../lib/AxiosClient';
import AuthService from '../lib/AuthService';
import AppToaster from '../lib/Toaster';
import HelpArticles from '../components/homeComponents/helpArticles';
import TemplateCatalog from '../components/homeComponents/templateCatalog';
import Header from '../components/homeComponents/header';
import InitialDownloadWidget from '../components/initialDownloadWidget';

class HomePage extends React.Component {
  state = {};

  constructor(props) {
    super(props);
    this.state = {
      initialLoadingDone: false,
      masterEditions: {},
      workbooks: [],
      currentScreen: 'workbooks',
      toasts: [],
    };

    // Store the Auth provider
    this.authProvider = AuthService.getInstance().provider;

    // Get the shopify app bridge and app bridge API key
    if (this.authProvider === 'shopify') {
      this.appBridgeApp = AuthService.getInstance().appBridgeApp;
      this.appBridgeApiKey = AuthService.getInstance().appBridgeApiKey;
    }

    /* Set up the toaster */
    this.toaster = AppToaster;
    this.refHandlers = {
      toaster: ref => this.toaster = ref,
    };

    /* Create our logger */
    this.logger = new Logger('HomePage');
  }

  async componentDidMount() {
    try {
      /* Get all workspaces */
      const workspaces = await this.getWorkspaces();

      /* Check if we have any workspaces */
      if (!workspaces || workspaces.length <= 0) {
        this.showError('No workspaces found');
        return;
      }

      /* Get the current workspace */
      const currentWorkspace = workspaces[0];

      /* Get data in parallel */
      const [workspaceStores] = await Promise.all([
        /* Get the Shopify stores in the current workspace */
        this.getWorkspaceShopifyStoresWithAppsAndSubscriptions(currentWorkspace.id),
        /* Load all user workbooks */
        this.loadUserWorkbooks(),
      ]);

      this.setState({
        workspaceStores, // result of first function
        currentWorkspace,
        initialLoadingDone: true,
      });
    } catch (err) {
      this.logger.error(`Error while loading home page: ${JSON.stringify(err)}`);
    }
  }

  /**
   * Shows a regular, status-update toast
   */
  showToast = (toastData) => {
    this.toaster.show({
      message: toastData.message,
      icon: toastData.icon,
      intent: toastData.intent,
    });
  }

  /**
   * Display an error Toast2 to the user (copy of the one from spreadsheet component)
   * @param err
   */
  showError = (err) => {
    const errorMessage = err.response && err.response.data && err.response.data.message ? err.response.data.message : err.toString();

    this.toaster.show({
      message: errorMessage,
      icon: 'issue',
      intent: Intent.DANGER,
    });
  }

  /**
   * Loads all workspaces user has
   * @returns {Promise<*[]>}
   */
  getWorkspaces = async () => {
    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/workspaces/`);

      return response.data;
    } catch (err) {
      this.logger.error('Home - componentDidMount', `Error loading workspace: ${err}`);

      this.showError(err);
    }
    return [];
  }

  /**
   * Load the workbooks that the user has access to
   * @returns {Promise<void>}
   */
  loadUserWorkbooks = async () => {
    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/workbooks/`);

      const workbooks = response.data; // Workbooks we received
      const workbooksToSave = []; // Workbooks we are going to show
      const masterEditions = this.state.masterEditions || {};

      await asyncJS.eachLimit(workbooks, 10, async (workbook) => {
        /* Create a copy of the workbook because we are going to modify it */
        const workbookObject = JSON.parse(JSON.stringify(workbook));

        const masterEdition = await this.getWorkbookMasterEdition(workbook);

        if (!masterEdition) {
          this.logger.error('loadUserWorkbooks', `Error loading user workbooks no master edition for ${workbook.id}`);
          // this.showError(`No master edition for ${workbook.name}`);
          return;
        }
        /* Save the master edition */
        masterEditions[workbook.id] = masterEdition;

        /* Get all the stores connected to edition worksheets */
        const stores = this.getStoresConnectedToWorkbookEdition(masterEdition);
        workbookObject.stores = stores || [];

        workbooksToSave.push(workbookObject);
      });

      /* Save the workbooks, master editions and stores */
      this.setState({
        workbooks: workbooksToSave,
        masterEditions,
      });
    } catch (err) {
      this.logger.error('loadUserWorkbooks', `Error loading user workbooks: ${err}`);

      this.showError(err);
    }
  }

  /**
   * Gets the master edition of a given workbook
   * @param workbook
   */
  getWorkbookMasterEdition = async (workbook) => {
    try {
      const responseWorkbookMaster = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/workbooks/${workbook.id}/master`);

      return responseWorkbookMaster.data;
    } catch (err) {
      console.log(`Error loading workbook master: ${err}`);

      this.showError(err);
    }
    return null;
  }

  /**
   * Helper method to extract the stores within edition worksheets
   * @returns {any[]}
   * @param workbookEdition
   */
  getStoresConnectedToWorkbookEdition = (workbookEdition) => {
    const worksheets = workbookEdition.worksheets;

    const stores = [];

    worksheets.forEach((worksheet) => {
      const store = _.get(worksheet, 'metadata.dataSource.shopify.store');
      if (store && !stores.includes(store)) {
        stores.push(store);
      }
    });

    return stores;
  }

  openIntegrationsCatalogScreen = () => {
    this.setState({ currentScreen: 'integrationsCatalog' });
  };

  openWorkbooksScreen = () => {
    this.setState({ currentScreen: 'workbooks' });
  };

  /**
   * Get mixtable last subscription for each workspace store
   * @param workspaceId
   * @returns {Promise<any|*[]>}
   */
  getWorkspaceShopifyStoresWithAppsAndSubscriptions = async (workspaceId) => {
    try {
      const response = await AxiosClient.getInstance().get(`${API.API_URL}/1.0/billing/shopify/${workspaceId}/getWorkspaceShopifyStoresWithAppsAndSubscriptions`);
      return response.data;
    } catch (err) {
      this.logger.error(`Error in getWorkspaceIntegrationConfig ${JSON.stringify(err)}`);
      this.showError(err);
    }
    return [];
  }

  render() {
    return (
      <div className={styles.homeComponent}>

        {/* Toast component*/}
        <OverlayToaster position={Position.BOTTOM} ref={this.refHandlers.toaster}>
          {this.state.toasts.map((toast, index) => <Toast2 key={index} {...toast} />)}
        </OverlayToaster>

        {!this.state.initialLoadingDone && <div className={styles.loadingAnimationContainer}>
          <LoadingAnimation />
          <div style={{ marginTop: '20px' }}>Loading ...</div>
        </div>}

        {/* Sidebar */}
        {/* {this.authProvider !== 'shopify' && this.state.initialLoadingDone && this.state.currentWorkspace && <Sidebar*/}
        {/*  currentWorkspace={this.state.currentWorkspace}*/}
        {/*  openWorkbooksScreen={this.openWorkbooksScreen}*/}
        {/*  openIntegrationsCatalogScreen={this.openIntegrationsCatalogScreen}*/}
        {/* />}*/}

        {/* Header with welcome message and create workbook button if needed */}
        {this.state.initialLoadingDone && <Header/>}

        {/* Workbooks page */}
        {this.state.initialLoadingDone && <div className={styles.contentContainer}>

          {/* Left column*/}
          <div className={styles.leftColumn}>

            {/* Initial data download widget*/}
            {this.state.initialLoadingDone && this.state.workspaceStores && Object.keys(this.state.workspaceStores).map(storeId => (
              <InitialDownloadWidget key={storeId} storeId={storeId} />
            ))}

            {/* We have user workbooks to display*/}
            {this.state.workbooks && <WorkbooksPage
              showError={this.showError}
              workspaceId={this.state.currentWorkspace.id}
              workbooks={this.state.workbooks}
              masterEditions={this.state.masterEditions}
              storesAppsInstalled={this.state.storesAppsInstalled}
              openIntegrationsCatalogScreen={this.openIntegrationsCatalogScreen}
            />}


            <TemplateCatalog workspaceId={this.state.currentWorkspace.id} workspaceShopifyStores={this.state.workspaceStores} />
          </div>

          {/* Right column*/}
          <div className={styles.rightColumn}>

            {/* Help articles*/}
            <HelpArticles workspaceShopifyStores={this.state.workspaceStores}/>
          </div>
        </div>}

        {/* Integration Catalogs Screen */}
        {/* {this.state.initialLoadingDone && this.state.currentScreen === 'integrationsCatalog' && <div className={styles.contentContainer}>*/}
        {/*  <h3 className={styles.sectionHeading}>Integrations</h3>*/}
        {/*  {this.state.workbooks && <IntegrationsCatalog showToast={this.showToast} showError={this.showError} workspace={this.state.currentWorkspace}/>}*/}
        {/* </div>}*/}
      </div>
    );
  }
}

export default HomePage;
